export const TEMPLATES = {
  mixed:
         '*{margin:0;padding:0;box-sizing:border-box}body{width:100%;min-height:100vh}:root{--bg:hsl(0, 0%, 92%);--border-clr:hsl(0, 0%, 85%);--bdr-rad:0.4em;--gap:0.8em;--font-size:1rem;--text-color:black;--special-text-color:grey;--shadow:0,0%,0%}@media (prefers-color-scheme:dark){:root{--bg:hsl(0, 0%, 22%);--border-clr:hsl(0, 0%, 30%);--bdr-rad:0.4em;--gap:0.8em;--font-size:1rem;--text-color:rgb(247, 247, 247);--special-text-color:grey;--shadow:0,0%,35%}.hyperlinks-container{box-shadow:0 .1em .4em hsla(var(--shadow),.4)}}.hyperlinks-tooltip{position:fixed;animation:fade-out .15s linear forwards;opacity:0}@keyframes fade-out{to{opacity:1}}.hyperlinks-container{width:min(58vw,55ch);height:auto;border:.125em solid var(--border-clr);border-radius:var(--bdr-rad);font-family:sans-serif;background:var(--bg);color:var(--text-color);box-shadow:0 .1em .4em 0 hsla(var(--shadow),.2);user-select:none}.hyperlinks-container img{max-width:100%;height:100%;object-fit:cover;background-position:center;pointer-events:none}.hyperlinks-container,.hyperlinks-site-info-container,.hyperlinks-site-info-container>*{overflow:hidden}.hyperlinks-img-container{border-bottom:.2em solid var(--border-clr)}.hyperlinks-site-info-container{padding:calc(var(--gap) - .2em);line-height:1.3}.hyperlinks-site-info-container>*{text-overflow:ellipsis;white-space:nowrap;font-size:var(--font-size);letter-spacing:.02rem}.hyperlinks-title{margin-bottom:calc(var(--gap) + .5em)}.hyperlinks-site-url{color:var(--special-text-color)}@media (min-width:37em){:root{--gap:1.2em}.hyperlinks-container{display:grid;grid-template-columns:1fr 1.3fr}.hyperlinks-container img{display:block;min-height:10em}.hyperlinks-site-info-container{display:flex;flex-direction:column;row-gap:var(--gap);padding-block:calc(var(--gap) + 0.5em)}.hyperlinks-sub-title{margin-top:auto}.hyperlinks-img-container{border:none}}',
  'light-vertical':
        ':root{--bg:hsl(0, 0%, 92%);--border-clr:hsl(0, 0%, 85%);--bdr-rad:0.4em;--gap:0.8em;--font-size:1rem;--text-color:black;--special-text-color:grey;--shadow:0,0%,0%}.hyperlinks-tooltip{position:fixed;animation:fade-out .15s linear forwards;opacity:0}@keyframes fade-out{to{opacity:1}}.hyperlinks-container{width:min(60vw,35ch);height:auto;border:.125em solid var(--border-clr);border-radius:var(--bdr-rad);font-family:sans-serif;background:var(--bg);color:var(--text-color);box-shadow:0 .1em .4em 0 hsla(var(--shadow),.2);user-select:none}.hyperlinks-container img{max-width:100%;height:100%;object-fit:cover;background-position:center;pointer-events:none}.hyperlinks-container,.hyperlinks-site-info-container,.hyperlinks-site-info-container>*{overflow:hidden}.hyperlinks-img-container{border-bottom:.2em solid var(--border-clr)}.hyperlinks-site-info-container{padding:calc(var(--gap) - .2em);line-height:1.3;padding-top:0}.hyperlinks-site-info-container>*{text-overflow:ellipsis;white-space:nowrap;font-size:var(--font-size);letter-spacing:.02rem}.hyperlinks-title{margin-bottom:calc(var(--gap) + .4em)}.hyperlinks-site-url{color:var(--special-text-color)}',
  'light-horizontal':
        ':root{--bg:hsl(0, 0%, 92%);--border-clr:hsl(0, 0%, 85%);--bdr-rad:0.4em;--gap:1.2em;--font-size:1rem;--text-color:black;--special-text-color:grey;--shadow:0,0%,0%}.hyperlinks-tooltip{position:fixed;animation:fade-out .15s linear forwards;opacity:0}@keyframes fade-out{to{opacity:1}}.hyperlinks-container{width:min(80vw,55ch);height:auto;border:.125em solid var(--border-clr);border-radius:var(--bdr-rad);font-family:sans-serif;background:var(--bg);color:var(--text-color);box-shadow:0 .1em .4em hsla(var(--shadow),.2);user-select:none;display:grid;grid-template-columns:1fr 1.3fr}.hyperlinks-container img{max-width:100%;height:100%;object-fit:cover;background-position:center;pointer-events:none;display:block;min-height:10em}.hyperlinks-container,.hyperlinks-site-info-container,.hyperlinks-site-info-container>*{overflow:hidden}.hyperlinks-site-info-container{padding:calc(var(--gap) - .2em);line-height:1.3;display:flex;flex-direction:column;row-gap:var(--gap);padding-block:calc(var(--gap) + 0.5em)}.hyperlinks-site-info-container>*{text-overflow:ellipsis;white-space:nowrap;font-size:var(--font-size);letter-spacing:.02rem}.hyperlinks-title{margin-bottom:calc(var(--gap) + .5em)}.hyperlinks-site-url{color:var(--special-text-color)}.hyperlinks-sub-title{margin-top:auto}',
  'dark-vertical':
        ':root{--bg:hsl(0, 0%, 22%);--border-clr:hsl(0, 0%, 30%);--bdr-rad:0.4em;--gap:0.8em;--font-size:1rem;--text-color:rgb(247, 247, 247);--special-text-color:grey;--shadow:0,0%,35%}.hyperlinks-tooltip{position:fixed;animation:fade-out .15s linear forwards;opacity:0}@keyframes fade-out{to{opacity:1}}.hyperlinks-container{width:min(60vw,35ch);height:auto;border:.125em solid var(--border-clr);border-radius:var(--bdr-rad);font-family:sans-serif;background:var(--bg);color:var(--text-color);box-shadow:0 .1em .4em hsla(var(--shadow),.4);user-select:none}.hyperlinks-container img{max-width:100%;height:100%;object-fit:cover;background-position:center;pointer-events:none}.hyperlinks-container,.hyperlinks-site-info-container,.hyperlinks-site-info-container>*{overflow:hidden}.hyperlinks-img-container{border-bottom:.2em solid var(--border-clr)}.hyperlinks-site-info-container{padding:calc(var(--gap) - .2em);line-height:1.3}.hyperlinks-site-info-container>*{text-overflow:ellipsis;white-space:nowrap;font-size:var(--font-size);letter-spacing:.02rem}.hyperlinks-title{margin-bottom:calc(var(--gap) + .5em)}.hyperlinks-site-url{color:var(--special-text-color)}',
  'dark-horizontal':
         ':root{--bg:hsl(0, 0%, 22%);--border-clr:hsl(0, 0%, 30%);--bdr-rad:0.4em;--gap:0.8em;--font-size:1rem;--text-color:rgb(247, 247, 247);--special-text-color:grey;--shadow:0,0%,35%}.hyperlinks-tooltip{position:fixed;animation:fade-out .15s linear forwards;opacity:0}@keyframes fade-out{to{opacity:1}}.hyperlinks-container{width:min(80vw,55ch);height:auto;border:.125em solid var(--border-clr);border-radius:var(--bdr-rad);font-family:sans-serif;background:var(--bg);color:var(--text-color);box-shadow:0 .1em .4em hsla(var(--shadow),.4);user-select:none;display:grid;grid-template-columns:1fr 1.3fr}.hyperlinks-container img{max-width:100%;height:100%;object-fit:cover;background-position:center;display:block;min-height:10em;pointer-events:none}.hyperlinks-container,.hyperlinks-site-info-container,.hyperlinks-site-info-container>*{overflow:hidden}.hyperlinks-site-info-container{padding:calc(var(--gap) - .2em);line-height:1.3;display:flex;flex-direction:column;row-gap:var(--gap);padding-block:calc(var(--gap) + 0.5em)}.hyperlinks-site-info-container>*{text-overflow:ellipsis;white-space:nowrap;font-size:var(--font-size);letter-spacing:.02rem}.hyperlinks-title{margin-bottom:calc(var(--gap) + .5em)}.hyperlinks-site-url{color:var(--special-text-color)}.hyperlinks-sub-title{margin-top:auto}'

}
